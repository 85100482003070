export const firebaseConfig = {
  apiKey: "AIzaSyAyPMuIGreq81gLErYeKGkEje851Xdh_kc",
  authDomain: "wavelength-online.firebaseapp.com",
  databaseURL: "https://wavelength-online.firebaseio.com",
  projectId: "wavelength-online",
  storageBucket: "wavelength-online.appspot.com",
  messagingSenderId: "811157116454",
  appId: "1:811157116454:web:be3d3e6b17629700c038e7",
  measurementId: "G-XZ13S15LMC",
};
